<template>
    <v-container>
        <v-row no-gutters align="start" justify="start">
            <v-btn class="ma-2"  dark href="/checklist">Zpět na výpis
                <v-icon dark right>mdi-atom-variant</v-icon>
            </v-btn>
        </v-row>
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="10" md="6" lg="4">
    <v-card
        outlined
        elevation="12"
        ref="form"
    >
        <form id="CheckListAdd" method="post" action="/checklist" enctype="application/x-www-form-urlencoded">
            <input name="_token" v-model="csrf" type="hidden" />
            <input type="hidden" name="spz_truck" v-model="Form.spz_truck" />
            <input type="hidden" name="spz_cistern" v-model="Form.spz_cistern" />
            <input type="hidden" name="company" v-model="Form.company" />
            <input type="hidden" name="driver_name" v-model="Form.driver_name" />
            <input type="hidden" name="driver_last_name" v-model="Form.driver_last_name" />
            <!-- technicka kontrola -->
            <input type="hidden" name="wheelsimg" id="wheelsimg" v-model="Form.wheelsimg" />
            <input type="hidden" name="wheelsOK" v-model="Form.wheelsOK" />
            <input type="hidden" name="wheels" v-model="Form.wheels" />
            <input type="hidden" name="adrimg" id="adrimg" v-model="Form.adrimg" />
            <input type="hidden" name="adrOK" v-model="Form.adrOK" />
            <input type="hidden" name="adr" v-model="Form.adr" />
            <input type="hidden" name="spzimg" id="spzimg" v-model="Form.spzimg" />
            <input type="hidden" name="spzOK" v-model="Form.spzOK">
            <input type="hidden" name="spz" v-model="Form.spz">
            <input type="hidden" name="cistern_lightsimg" id="cistern_lightsimg" v-model="Form.cistern_lightsimg" />
            <input type="hidden" name="cistern_lightsOK" v-model="Form.cistern_lightsOK">
            <input type="hidden" name="cistern_lights" v-model="Form.cistern_lights">
            <!-- vjezdova kontrola -->
            <input type="hidden" name="cabimg" id="cabimg" v-model="Form.cabimg" />
            <input type="hidden" name="cabOK" v-model="Form.cabOK">
            <input type="hidden" name="cab" v-model="Form.cab">
            <input type="hidden" name="storage_spaceimg" id="img" v-model="Form.storage_spaceimg" />
            <input type="hidden" name="storage_spaceOK" v-model="Form.storage_spaceOK">
            <input type="hidden" name="storage_space" v-model="Form.storage_space">
            <input type="hidden" name="behind_cabimg" id="behind_cabimg" v-model="Form.behind_cabimg" />
            <input type="hidden" name="behind_cabOK" v-model="Form.behind_cabOK">
            <input type="hidden" name="behind_cab" v-model="Form.behind_cab">
            <input type="hidden" name="plombimg" id="plombimg" v-model="Form.plombimg" />
            <input type="hidden" name="plombOK" v-model="Form.plombOK">
            <input type="hidden" name="plomb" v-model="Form.plomb">
            <input type="hidden" name="tubesimg" id="tubesimg" v-model="Form.tubesimg" />
            <input type="hidden" name="tubesOK" v-model="Form.tubesOK">
            <input type="hidden" name="tubes" v-model="Form.tubes">
            <input type="hidden" name="chassisimg" id="chassisimg" v-model="Form.chassisimg" />
            <input type="hidden" name="chassisOK" v-model="Form.chassisOK">
            <input type="hidden" name="chassis" v-model="Form.chassis">
            <input type="hidden" name="note" v-model="Form.note">
        <v-toolbar
            color="primary"
            dark
            flat
        >
            <v-toolbar-title >NOVÝ CHECKLIST - VJEZDOVÁ KONTROLA</v-toolbar-title>
            <v-spacer />
        </v-toolbar>
        <v-card-text>

            <v-form v-model="Form.valid" ref="form">
                <v-stepper v-model="stepper_index" vertical>
                    <v-stepper-step :complete="stepper_index > 1" step="1">
                        <div class="d-inline red--text">SPZ - No GO !</div>
                    </v-stepper-step>
                    <v-stepper-content step="1">

                        <v-text-field
                            outlined
                            dense
                            placeholder="XXX XXXX"
                            label="SPZ tahač"
                            class="ma-2 dropdown"
                            v-model="Form.spz_truck"
                            @input="autocomplete($event)"
                            :rules="[v => !!v || 'SPZ tahače je nutné vyplnit']"
                        ></v-text-field>
                        <v-card
                            class="mx-auto list-content"
                            max-width="650"
                            v-if="autocomplete_filtered"
                        >
                            <v-list dense>
                                <v-list-item-group>
                                    <v-list-item v-for="item in autocomplete_filtered_slice" :key="item.spz_truck" @click="setFromAutocomplete(item)">
                                        <v-list-item-content>{{ item.spz_truck }}/{{ item.spz_cistern }} - {{ item.company }} - {{ item.driver_name }} {{ item.driver_last_name }}</v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card>
                        <v-text-field
                            outlined
                            dense
                            placeholder="XXX XXXX"
                            label="SPZ návěs"
                            class="ma-2"
                            v-model="Form.spz_cistern"
                            @input="v => { Form.spz_cistern = v.toUpperCase()}"
                        ></v-text-field>
                        <v-btn color="primary" @click="stepper_index = 2" :disabled="Form.spz_truck==null">Další</v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="stepper_index > 2" step="2">
                        <div class="d-inline red--text">Dopravce - No GO !</div>
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <v-text-field
                            outlined
                            dense
                            placeholder="Dopravce"
                            label="Dopravce"
                            class="ma-2"
                            v-model="Form.company"
                            :rules="[v => !!v || 'Dopravce je nutné vyplnit']"
                        ></v-text-field>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="stepper_index > 3" step="3">
                        <div class="d-inline red--text">Řidič - No GO !</div>
                    </v-stepper-step>
                    <v-stepper-content step="3">
                        <v-text-field
                            outlined
                            dense
                            placeholder="jméno řidiče"
                            label="Jméno"
                            class="ma-2"
                            v-model="Form.driver_name"
                            :rules="[v => !!v || 'Jméno řidiče je nutné vyplnit']"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            placeholder="příjmení řidiče"
                            label="Příjmení"
                            class="ma-2"
                            v-model="Form.driver_last_name"
                            :rules="[v => !!v || 'Příjmení řidiče je nutné vyplnit']"
                        ></v-text-field>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- WHEELS -->
                    <v-stepper-step step="4" :complete="stepper_index > 4">Pneumatiky</v-stepper-step>
                    <v-stepper-content step="4">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.wheelsOK=!Form.wheelsOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: pneumatiky"
                                    class="ma-2"
                                    v-show="!Form.wheelsOK"
                                    v-model="Form.wheels"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.wheelsOK" @click="DialogPhoto('Pneumatiky', 'wheels')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.wheelsOK" width="120" height="60" id="canvas_wheels"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_wheels_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- ADR -->
                    <v-stepper-step step="5" :complete="stepper_index > 5">
                        <div class="d-inline red--text">ADR - No GO!</div>
                    </v-stepper-step>
                    <v-stepper-content step="5">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.adrOK=!Form.adrOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: ADR"
                                    class="ma-2"
                                    v-show="!Form.adrOK"
                                    v-model="Form.adr"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" class="ma-2" v-show="!Form.adrOK" @click="DialogPhoto('ADR', 'adr')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.adrOK" width="120" height="60" id="canvas_adr"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_adr_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- SPZ -->
                    <v-stepper-step step="6" :complete="stepper_index > 6">SPZ</v-stepper-step>
                    <v-stepper-content step="6">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.spzOK=!Form.spzOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: SPZ"
                                    class="ma-2"
                                    v-show="!Form.spzOK"
                                    v-model="Form.spz"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.spzOK" @click="DialogPhoto('SPZ', 'spz')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.spzOK" width="120" height="60" id="canvas_spz"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_spz_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- CISTERN LIGHTS -->
                    <v-stepper-step step="7" :complete="stepper_index > 7">Osvětlení cisterny</v-stepper-step>
                    <v-stepper-content step="7">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.cistern_lightsOK=!Form.cistern_lightsOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Osvětlení cisterny"
                                    class="ma-2"
                                    v-show="!Form.cistern_lightsOK"
                                    v-model="Form.cistern_lights"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.cistern_lightsOK" @click="DialogPhoto('Osvětlení cisterny', 'cistern_lights')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.cistern_lightsOK" width="120" height="60" id="canvas_cistern_lights"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_cistern_lights_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- DRIVER CAB -->
                    <v-stepper-step step="8" :complete="stepper_index > 8">Kabina řidiče</v-stepper-step>
                    <v-stepper-content step="8">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.cabOK=!Form.cabOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Kabina řidiče"
                                    class="ma-2"
                                    v-show="!Form.cabOK"
                                    v-model="Form.cab"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.cabOK" @click="DialogPhoto('Kabina řidiče', 'cab')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.cabOK" width="120" height="60" id="canvas_cab"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_cab_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- STORAGE SPACE -->
                    <v-stepper-step step="9" :complete="stepper_index > 9">Úložné prostory z vně kabiny</v-stepper-step>
                    <v-stepper-content step="9">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.storage_spaceOK=!Form.storage_spaceOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Úložné prostory"
                                    class="ma-2"
                                    v-show="!Form.storage_spaceOK"
                                    v-model="Form.storage_space"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.storage_spaceOK" @click="DialogPhoto('Úložné prostory', 'storage_space')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.storage_spaceOK" width="120" height="60" id="canvas_storage_space"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_storage_space_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- BEHIND CAB-->
                    <v-stepper-step step="10" :complete="stepper_index > 10">Prostor za kabinou</v-stepper-step>
                    <v-stepper-content step="10">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.behind_cabOK=!Form.behind_cabOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Prostor za kabinou"
                                    class="ma-2"
                                    v-show="!Form.behind_cabOK"
                                    v-model="Form.behind_cab"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.behind_cabOK" @click="DialogPhoto('Prostor za kabinou', 'behind_cab')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.behind_cabOK" width="120" height="60" id="canvas_behind_cab"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_behind_cab_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- PLOMB-->
                    <v-stepper-step step="11" :complete="stepper_index > 11">Plnící skříň a plomby</v-stepper-step>
                    <v-stepper-content step="11">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.plombOK=!Form.plombOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Plnící skříň a plomby"
                                    class="ma-2"
                                    v-show="!Form.plombOK"
                                    v-model="Form.plomb"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.plombOK" @click="DialogPhoto('Plnící skříň a plomby', 'plomb')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.plombOK" width="120" height="60" id="canvas_plomb"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_plomb_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- TUBES -->
                    <v-stepper-step step="12" :complete="stepper_index > 12">Tubusy na hadice</v-stepper-step>
                    <v-stepper-content step="12">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.tubesOK=!Form.tubesOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Tubusy na hadice"
                                    class="ma-2"
                                    v-show="!Form.tubesOK"
                                    v-model="Form.tubes"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.tubesOK" @click="DialogPhoto('Tubusy na hadice', 'tubes')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.tubesOK" width="120" height="60" id="canvas_tubes"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_tubes_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!-- CHASSIS -->
                    <v-stepper-step step="13" :complete="stepper_index > 13">Celková kontrola rámu</v-stepper-step>
                    <v-stepper-content step="13">
                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.chassisOK=!Form.chassisOK" true-value="1" false-value="0"></v-switch>
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    outlined
                                    dense
                                    placeholder="popis nedostatků"
                                    label="Nedostatky: Celková kontrola rámu"
                                    class="ma-2"
                                    v-show="!Form.chassisOK"
                                    v-model="Form.chassis"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="start">
                            <v-col>
                                <v-btn color="error" v-show="!Form.chassisOK" @click="DialogPhoto('Celková kontrola rámu', 'chassis')">
                                    <v-icon left>mdi-camera-outline</v-icon>
                                    F O T O
                                </v-btn>
                            </v-col>
                            <v-col>
                                <canvas v-show="!Form.chassisOK" width="120" height="60" id="canvas_chassis"></canvas>
                            </v-col>
                        </v-row>
                        <canvas hidden id="canvas_chassis_big" width="1280" height="720"></canvas>
                        <v-spacer/>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                    </v-stepper-content>
                    <!--  NOTES -->
                    <v-stepper-step step="14" :complete="stepper_index > 14">Poznámka</v-stepper-step>
                    <v-stepper-content step="14">
                        <v-row no-gutters>
                            <v-col>
                                <v-textarea
                                    outlined
                                    dense
                                    placeholder="Poznámka"
                                    label="Poznámka"
                                    class="ma-2"
                                    v-model="Form.note"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                    </v-stepper-content>
                </v-stepper>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn class="ma-2" color="secondary" @click="FormSend('CheckListAdd')" :disabled="stepper_index < 14">Uložit</v-btn>
        </v-card-actions>
        </form>
    </v-card>
    <v-dialog
        v-model="dialog"
        fullscreen
    >
        <v-card>
            <!-- <v-card-title class="headline justify-center">Fotoaparát - {{ dialog_title }}</v-card-title> -->
            <v-card-text class="text-center">
                <v-row no-gutters align="center" justify="center" align-content="center">
                    <v-col>
                        <video ref="video" id="vid-show" width="80%" height="80%" autoplay></video>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="PhotoSave">
                    <v-icon left>mdi-camera-outline</v-icon>
                    Vyfotit a uložit {{ dialog_title }}
                </v-btn>
                <v-btn color="primary" @click="dialog = false">Zavřít</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "CheckListAdd",
    data () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            stepper_index: 1,
            dialog: false,
            dialog_title: '',
            canvas_name: '',
            autocomplete_values: [],
            autocomplete_value: '',
            autocomplete_filtered: [],
            Form: {
                spz_truck: null,
                spz_cistern: null,
                driver_name: null,
                driver_last_name: null,
                company: null,
                /* Technicka kontrola */
                wheelsOK: true,
                wheels:null,
                wheelsimg: null,
                adrOK: true,
                adr: null,
                adrimg: null,
                spzOK: true,
                spz: null,
                spzimg: null,
                cistern_lightsOK: true,
                cistern_lights: null,
                cistern_lightsimg: null,

                /* Vjezdova kontrola */
                cabOK: true,
                cab: null,
                cabimg: null,
                storage_spaceOK: true,
                storage_space: null,
                storage_spaceimg: null,
                behind_cabOK: true,
                behind_cab: null,
                behind_cabimg: null,
                plombOK: true,
                plomb: null,
                plombimg: null,
                tubesOK: true,
                tubes: null,
                tubesimg: null,
                chassisOK: true,
                chassis: null,
                chassisimg: null,
                note: null,

                valid: false
            }
        }
    },
    methods: {
        autocomplete(value) {
            if (value.length > 2) {
                this.autocomplete_filtered = this.autocomplete_values.filter(item => {
                    return item.spz_truck.toUpperCase().startsWith(this.Form.spz_truck.toUpperCase())
                })
            } else {
                this.autocomplete_filtered = []
            }
            this.Form.spz_truck = value.toUpperCase()
        },
        setFromAutocomplete(item) {
            this.Form.spz_truck = item.spz_truck.toUpperCase()
            this.Form.spz_cistern = item.spz_cistern.toUpperCase()
            this.Form.company = item.company
            this.Form.driver_name = item.driver_name
            this.Form.driver_last_name = item.driver_last_name
            this.autocomplete_filtered = []
        },
        async DialogPhoto(title, name) {
            this.dialog_title = title
            this.canvas_name = name
            this.dialog = true
            document.querySelector('video').srcObject = await navigator.mediaDevices.getUserMedia({ video : {
//predni kamera                    facingMode: 'user',
                    facingMode: 'environment',
                    frameRate: { ideal: 10, max: 15 },
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                } , audio: false})
        },
        PhotoSave() {
            let canvas = document.getElementById('canvas_'+this.canvas_name)
            canvas.width = 120
            canvas.height = 60
            canvas.getContext('2d').drawImage(document.querySelector('video'), 0, 0, 120, 60)
            let canvasBig = document.getElementById('canvas_'+this.canvas_name+'_big')
            canvasBig.width = 1280
            canvasBig.height = 720
            canvasBig.getContext('2d').drawImage(document.querySelector('video'), 0, 0, 1280, 720)
            const reader = new FileReader();
            let self = this
            canvasBig.toBlob(function (blob){
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    eval("self.Form."+self.canvas_name+"img = reader.result");
                }
            }, 'image/jpeg', 0.9)
            this.dialog = false
        },
        FormSend(FormName) {
            /*
            if (!this.Form.wheelsOK) {
                this.Form.wheelsimg = null
                let formWheels = document.getElementById('wheelsimg')
                formWheels.value = ""
            }
            if (!this.Form.cistern_lightsOK) {
                this.Form.cistern_lights = null
                let formTank = document.getElementById('tankimg')
                formTank.value = ""
            }
            */
            let form2send = document.getElementById(FormName)
            form2send.submit()
        }
    },
    computed: {
        autocomplete_filtered_slice: function () {
            return this.autocomplete_filtered.slice(0,5)
        }
    },
    mounted() {
        axios.post('/api/spz',  this.Form)
            .then((response) => {
                this.autocomplete_values = response.data
            }, (error) => {
                console.log(error);
            });
    }
}
</script>

<style scoped>
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
}

.list-content {
    position: absolute;
    z-index: 9999;
    top: 120px;
    left: 50px;
}

</style>
