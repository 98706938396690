<template>
    <v-container>
        <v-row no-gutters align="start" justify="start">
            <v-btn class="ma-2"  dark href="/checklist/create">Nová kontrola/vjezd
                <v-icon dark right>mdi-shape-circle-plus</v-icon>
            </v-btn>
        </v-row>
        <v-row no-gutters align="start" justify="center">
            <v-col
                cols="12"
                lg="8"
            >
                <!-- ENTRY -->
                <v-simple-table
                    dense
                    fixed-header
                    id="mytable_e"
                    v-show="checklist_e.length"
                >
                    <thead>
                    <tr>
                        <th colspan="7" class="text-center">VOZIDLA PŘIJATÁ</th>
                    </tr>
                    <tr>
                        <th class="text-center">Akce</th>
                        <th class="text-center">Datum/čas vjezdu</th>
                        <th class="text-center">Autor</th>
                        <th class="text-center">Firma</th>
                        <th class="text-center">SPZ</th>
                        <th class="text-center">Řidič</th>
                        <th class="text-center">Nedostatky</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in checklist_e" :key="item.id">
                            <td class="text-center">
                                <v-tooltip bottom color="red">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-if="role=='admin'" @click="DeleteItem(item.id)" v-on="on">
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Smazat checklist</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon :href="'/checklist/complete/'+item.id" v-on="on">
                                            <v-icon>mdi-location-exit</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Pokračovat odjezdovou kontrolou</span>
                                </v-tooltip>
                            </td>
                            <td class="text-center">{{ DateCZ(item.created_at) }}</td>
                            <td class="text-center">{{ item.author }}</td>
                            <td class="text-center">{{ item.company }}</td>
                            <td class="text-center">{{ item.spz_truck }}/{{ item.spz_cistern }}</td>
                            <td class="text-center">{{ item.driver_name }} {{ item.driver_last_name }}</td>
                            <td class="text-center">{{ CountProblems(item) }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-divider class="ma-5"></v-divider>
            </v-col>
        </v-row>
        <v-row  justify="center" v-if="role=='admin'">
            <v-col
                cols="12"
                lg="8"
            >
                <v-form>
                <v-row align="center" justify="center" no-gutters>
                    <v-col>
                        <v-menu
                            v-model="date_od_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="computedDateOdFormatted"
                                    label="Datum od"
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="Form.datum_od"
                                @input="date_od_menu = false"
                                locale="cs"
                                @change="ShowCompleted"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-menu
                            v-model="date_do_menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="computedDateDoFormatted"
                                    label="Datum do"
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="Form.datum_do"
                                @input="date_do_menu = false"
                                locale="cs"
                                @change="ShowCompleted"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-text-field
                            outlined
                            dense
                            placeholder="Dopravce"
                            label="Dopravce"
                            class="ma-2"
                            v-model="Form.company"
                            :rules="[v => (v.length === 0 || v.length > 2) || 'Minimálně 3 znaky']"
                            @keyup.enter="ShowCompleted"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            outlined
                            dense
                            placeholder="SPZ"
                            label="SPZ"
                            class="ma-2"
                            v-model="Form.spz"
                            :rules="[v => (v.length === 0 || v.length > 2) || 'Minimálně 3 znaky']"
                            @keyup.enter="ShowCompleted"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            outlined
                            dense
                            placeholder="Jméno řidiče"
                            label="Řidič"
                            class="ma-2"
                            v-model="Form.driver"
                            :rules="[v => (v.length === 0 || v.length > 2) || 'Minimálně 3 znaky']"
                            @keyup.enter="ShowCompleted"
                        ></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
            </v-col>
        </v-row>
        <!-- COMPLETED -->
        <v-row no-gutters align="start" justify="center">
            <v-col
                cols="12"
                lg="8"
            >
                <v-simple-table
                    dense
                    fixed-header
                    id="mytable_c"
                    v-show="checklist_c.length"
                >
                    <thead>
                    <tr>
                        <th colspan="7" class="text-center">VOZIDLA ODJETÁ</th>
                    </tr>
                    <tr>
                        <th class="text-center">Akce</th>
                        <th class="text-center">Datum/čas výjezdu</th>
                        <th class="text-center">Autor</th>
                        <th class="text-center">Firma</th>
                        <th class="text-center">SPZ</th>
                        <th class="text-center">Řidič</th>
                        <th class="text-center">Nedostatky</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in checklist_c" :key="item.id">
                        <td class="text-center">
                            <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-if="role=='admin'" @click="DeleteItem(item.id)" v-on="on">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Smazat checklist</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn :href="'/checklist/'+item.id" icon v-on="on">
                                        <v-icon>mdi-card-search-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Zobrazit checklist</span>
                            </v-tooltip>
                        </td>
                        <td class="text-center">{{ DateCZ(item.updated_at) }}</td>
                        <td class="text-center">{{ item.author }}</td>
                        <td class="text-center">{{ item.company }}</td>
                        <td class="text-center">{{ item.spz_truck }}/{{ item.spz_cistern }}</td>
                        <td class="text-center">{{ item.driver_name }} {{ item.driver_last_name }}</td>
                        <td class="text-center">{{ CountProblems(item) }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-snackbar
            timeout=3000
            top
            color="success"
            v-model="success_message"
        >{{ success_message }}</v-snackbar>
        <v-snackbar
            timeout=3000
            top
            color="error"
            v-model="error_message"
        >{{ error_message }}</v-snackbar>
        <confirm ref="confirm"></confirm>
    </v-container>
</template>

<script>
import Confirm from './Confirm.vue'

    export default {
        name: "CheckList.vue",
        props: {
            role: String,
            checklist_entry: String,
        },
        components: {
          Confirm
        },
        data: function () {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                checklist_e: [],
                checklist_c: [],
                Form: {
                    datum_od: new Date().toISOString().substr(0, 10),
                    datum_do: new Date().toISOString().substr(0, 10),
                    spz: '',
                    company: '',
                    driver: ''
                },
                date_od_menu: false,
                date_do_menu: false,
                success_message: null,
                error_message: null
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            },
            ShowCompleted() {
                if ((this.Form.spz.length===0 || this.Form.spz.length>2) && (this.Form.company.length===0 || this.Form.company.length>2) && (this.Form.driver.length===0 || this.Form.driver.length>2)) {
                    /*console.log("SEND")
                    console.log(this.Form.spz.length)
                    console.log(this.Form.company.length)
                    console.log(this.Form.driver.length)*/
                    this.LoadCheckListsCompleted()
                }
            },
            LoadCheckListsCompleted() {
                //console.log('LoadCheckListsCompleted')
                axios.post('/api/checklist',  this.Form)
                    .then((response) => {
                        this.checklist_c = response.data
                    }, (error) => {
                        console.log(error);
                    });
            },
            DeleteItem(id) {
                this.$root.$confirm('Smazat', 'Opravdu chcete smazat checklist?', { color: 'red' }).then((confirm) => {
                    if (confirm) {
                        axios.delete('/checklist/delete/'+id)
                            .then((response) => {
                                console.log(response.data)
                                this.LoadCheckListsCompleted()
                            }, (error) => {
                                console.log(error);
                            });
                    }
                })
            },
            DateCZ(laraveldate) {
                let r = new Date(laraveldate)
                return r.toLocaleString('cs-CZ')
            },
            CountProblems(item) {
                let problems = 0
                if (!item.wheelsOK) problems++
                if (!item.adrOK) problems++
                if (!item.spzOK) problems++
                if (!item.cistern_lightsOK) problems++
                if (!item.cabOK) problems++
                if (!item.storage_spaceOK) problems++
                if (!item.behind_cabOK) problems++
                if (!item.plombOK) problems++
                if (!item.tubesOK) problems++
                if (!item.chassisOK) problems++
                if (item.note!==null) problems++
                if (!item.exit_plombOK) problems++
                if (!item.exit_chassisOK) problems++
                if (!item.exit_cabOK) problems++
                if (item.exit_note!==null) problems++

                return problems
            }
        },
        mounted() {
            this.checklist_e = JSON.parse(this.checklist_entry)
            this.LoadCheckListsCompleted()
            this.$root.$confirm = this.$refs.confirm.open
        },
        computed: {
            computedDateOdFormatted () {
                return this.formatDate(this.Form.datum_od)
            },
            computedDateDoFormatted () {
                return this.formatDate(this.Form.datum_do)
            },
        }
    }
</script>

<style scoped>

</style>
