<template>
    <v-container>
        <v-row no-gutters align="start" justify="start">
            <v-btn class="ma-2"  dark href="/checklist">Zpět na výpis
                <v-icon dark right>mdi-atom-variant</v-icon>
            </v-btn>
        </v-row>
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="10" md="6" lg="4">
                <v-card
                    outlined
                    elevation="12"
                    ref="form"
                >
                    <v-toolbar
                        color="green"
                        dark
                        flat
                    >
                        <v-toolbar-title>ZOBRAZENÍ CHECKLISTU - {{ checklist_id }}</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <p class="text-body-2">Dopravce: <span class="font-weight-bold">{{ checklist_e.company }}</span></p>
                        <p class="text-body-2">SPZ tahač: <span class="font-weight-bold">{{ checklist_e.spz_truck }}</span></p>
                        <p class="text-body-2">SPZ cisterny: <span class="font-weight-bold">{{ checklist_e.spz_cistern }}</span></p>
                        <p class="text-body-2">Řidič: <span class="font-weight-bold">{{ checklist_e.driver_name }} {{ checklist_e.driver_last_name }}</span></p>
                        <v-divider class="ma-2"></v-divider>
                        <v-divider class="ma-2"></v-divider>
                        <p class="text-body-1 text-center font-weight-bold">Technická kontrola</p>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Pneumatiky: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.wheelsOK" class="text-body-1">{{ checklist_e.wheels }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.wheelsOK" width="120" height="80" :src="'/image/wheels/'+checklist_e.id" @click="ShowImage(checklist_e.id, 'wheels')"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">ADR: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.adrOK" class="text-body-1">{{ checklist_e.adr }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.adrOK" width="120" height="80" :src="'/image/adr/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">SPZ: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.spzOK" class="text-body-1">{{ checklist_e.spz }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.spzOK" width="120" height="80" :src="'/image/spz/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Osvětlení cisterny: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.cistern_lightsOK" class="text-body-1">{{ checklist_e.cistern_lights }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.cistern_lightsOK" width="120" height="80" :src="'/image/cistern_lights/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-divider class="ma-2"></v-divider>
                        <p class="text-body-1 text-center font-weight-bold">Vjezdová kontrola</p>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Kabina řidiče: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.cabOK" class="text-body-1">{{ checklist_e.cab }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.cabOK" width="120" height="80" :src="'/image/cab/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Úložné prostory: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.storage_spaceOK" class="text-body-1">{{ checklist_e.storage_space }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.storage_spaceOK" width="120" height="80" :src="'/image/storage_space/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Prostor za kabinou: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.behind_cabOK" class="text-body-1">{{ checklist_e.behind_cab }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.behind_cabOK" width="120" height="80" :src="'/image/behind_cab/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Plnící skříň a plomby: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.plombOK" class="text-body-1">{{ checklist_e.plomb }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.plombOK" width="120" height="80" :src="'/image/plomb/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Tubusy a hadice: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.tubesOK" class="text-body-1">{{ checklist_e.tubes }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.tubesOK" width="120" height="80" :src="'/image/tubes/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Celková kontrola rámu: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.chassisOK" class="text-body-1">{{ checklist_e.chassis }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.chassisOK" width="120" height="80" :src="'/image/chassis/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Poznámka: </span></v-col>
                            <v-col align="left">
                                <span class="text-body-1">{{ checklist_e.note }}</span>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-divider class="ma-2"></v-divider>
                        <p class="text-body-1 text-center font-weight-bold">Odjezdová kontrola</p>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Celková kontrola rámu: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.exit_chassisOK" class="text-body-1">{{ checklist_e.exit_chassis }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.exit_chassisOK" width="120" height="80" :src="'/image/exit_chassis/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Plnící skříň a plomby: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.exit_plombOK" class="text-body-1">{{ checklist_e.exit_plomb }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.exit_plombOK" width="120" height="80" :src="'/image/exit_plomb/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Kabina řidiče: </span></v-col>
                            <v-col align="left">
                                <span v-if="!checklist_e.exit_cabOK" class="text-body-1">{{ checklist_e.exit_cab }}</span>
                                <span v-else class="text-body-1 green--text">OK</span>
                            </v-col>
                            <v-col>
                                <v-img v-if="!checklist_e.exit_cabOK" width="120" height="80" :src="'/image/exit_cab/'+checklist_e.id"></v-img>
                            </v-col>
                        </v-row>
                        <v-divider class="ma-2"></v-divider>
                        <v-row no-gutters align="start" justify="start">
                            <v-col align="left"><span class="text-body-1">Poznámka: </span></v-col>
                            <v-col align="left">
                                <span class="text-body-1">{{ checklist_e.exit_note }}</span>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" :href="'/checklist/pdf/'+checklist_e.id">Uložit jako PDF</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialog"
            fullscreen
        >
            <v-card>
                <v-card-title class="headline justify-center">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialog = false">Zavřít</v-btn>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="text-center">
                    <v-row no-gutters align="center" justify="center" align-content="center">
                        <v-col>
                            <v-img :src="dialog_img" height="100vh"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "CheckListShow.vue",
    props: {
        checklist: String
    },
    data () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            dialog: false,
            dialog_img: '',
            checklist_e: Object,
        }
    },
    methods:{
        ShowImage(id, type) {
            console.log('/image/'+type+'/'+id)
            this.dialog_img = '/image/'+type+'/'+id
            this.dialog = true

        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        }
    },
    mounted() {
        this.checklist_e = JSON.parse(this.checklist)
    },
    computed: {
        checklist_id: function (){
            if (!this.checklist_e.created_at) return null
            const [year, month, day] = new Date(this.checklist_e.created_at).toISOString().substr(0, 10).split('-')
            return `${year}${month}${day}/` + this.checklist_e.id
        }
    }
}
</script>

<style scoped>

</style>
