<template>
    <v-container>
        <v-snackbar
            timeout=3000
            top
            :color="snack.color"
            v-model="snack.message"
        >{{ snack.message }}</v-snackbar>
        <confirm ref="confirm"></confirm>
        <v-row no-gutters align="start" justify="start">
            <v-btn class="ma-2"  dark href="/user/create">Nový uživatel
                <v-icon dark right>mdi-account-plus-outline</v-icon>
            </v-btn>
        </v-row>
        <v-row no-gutters align="start" justify="center">
            <v-col
                cols="12"
                lg="8"
            >
                <v-simple-table
                    dense
                    fixed-header
                    id="mytable_e"
                    v-show="users.length"
                >
                    <thead>
                    <tr>
                        <th colspan="7" class="text-center">UŽIVATELÉ - PŘEHLED</th>
                    </tr>
                    <tr>
                        <th class="text-center">Akce</th>
                        <th class="text-center">Jméno</th>
                        <th class="text-center">Typ</th>
                        <th class="text-center">Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in users" :key="item.id">
                        <td class="text-center">
                            <v-tooltip bottom color="red">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-if="role=='admin'" @click="DeleteItem(item.id, item.name)" v-on="on">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Smazat uživatele</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn :href="'/user/edit/'+item.id" icon v-on="on">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Upravit uživatele</span>
                            </v-tooltip>
                        </td>
                        <td class="text-center">{{ item.name }}</td>
                        <td class="text-center">{{ item.role }}</td>
                        <td class="text-center">{{ item.email }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Confirm from './Confirm.vue'
export default {
    name: "Users.vue",
    props: {
      role: String
    },
    components: {
        Confirm
    },
    data: function () {
        return {
            users: [],
            snack: {
                message: null,
                color: 'success'
            }
        }
    },
    methods: {
        UsersLoad() {
            axios.post('/user/get',  this.Form)
                .then((response) => {
                    this.users = response.data
                }, (error) => {
                    console.log(error);
                });
        },
        DeleteItem(id, name) {
            this.$root.$confirm('Smazat', `Opravdu chcete smazat uživatele ${name}?`, { color: 'red' }).then((confirm) => {
                if (confirm) {
                    axios.delete('/user/delete/'+id)
                        .then((response) => {
                            console.log(response.data)
                            this.UsersLoad()
                        }, (error) => {
                            console.log(error);
                        });
                }
            })
        },
    },
    mounted() {
        this.UsersLoad()
        this.$root.$confirm = this.$refs.confirm.open
    }
}
</script>

<style scoped>

</style>
