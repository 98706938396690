<template>
    <v-col cols="6">
        <v-row>
            <v-menu
                v-model="date_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="computedDateFormatted"
                        label="Picker without buttons"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    @input="date_menu = false"
                    locale="cs"
                ></v-date-picker>
            </v-menu>
        </v-row>
    </v-col>
</template>
<script>
    export default {
        data () {
            return {
                date: new Date().toISOString().substr(0, 10),
                date_menu: false
            }
        },
        mounted() {
            console.log('Component mounted.')
        },
        methods: {
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}.${month}.${year}`
            }
        },
        computed: {
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
        },
    }
</script>
