<template>
    <v-container>
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="10" md="6" lg="4">
                <v-card
                    outlined
                    elevation="12"
                    ref="form"
                >
                    <v-toolbar
                        color="blue"
                        dark
                        flat
                    >
                        <v-toolbar-title >EDITACE UŽIVATELE</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <form id="UserEditForm" method="post" :action="'/user/update/'+user_e.id" enctype="application/x-www-form-urlencoded">
                            <input name="_token" v-model="csrf" type="hidden" />
                            <input name="id" v-model="user_e.id" type="hidden" />
                            <input name="name" v-model="user_e.name" type="hidden" />
                            <input name="email" v-model="user_e.email" type="hidden" />
                            <input name="password" v-model="user_e.password" type="hidden" />
                            <input name="role" v-model="user_e.role" type="hidden" />
                        </form>
                        <v-form v-model="form_valid">
                            <p class="text-body-2">Uživatel: <span class="font-weight-bold">{{ user_e.name }}</span></p>
                            <v-text-field
                                outlined
                                dense
                                placeholder="e-mail"
                                label="e-mail"
                                class="ma-2"
                                v-model="user_e.email"
                                :rules="[v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail musí mít správný formát']"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                dense
                                placeholder="heslo"
                                label="heslo"
                                class="ma-2"
                                v-model="user_e.password"
                                type="password"
                                :rules="[user_e.password === user_e.password_confirm || 'Hesla musí souhlasit', v => !v || v.length>3 || 'Heslo musí mít alespoň 4 znaky']"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                dense
                                placeholder="heslo - potvrzení"
                                label="heslo - potvrzení"
                                class="ma-2"
                                v-model="user_e.password_confirm"
                                type="password"
                                :rules="[user_e.password === user_e.password_confirm || 'Hesla musí souhlasit']"
                            ></v-text-field>
                            <v-checkbox
                                label="Administrátor"
                                v-model="user_e.role"
                                true-value="admin"
                                false-value="user"
                            ></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn class="ma-2" color="secondary" @click="FormSend('UserEditForm')" :disabled="!form_valid">Uložit</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "UserEdit.vue",
    props: {
        user: String
    },
    data () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            form_valid: false,
            user_e: Object
        }
    },
    methods: {
        FormSend(FormName) {
            let form2send = document.getElementById(FormName)
            form2send.submit()
        }
    },
    mounted() {
        this.user_e = JSON.parse(this.user)
    }
}
</script>

<style scoped>

</style>
