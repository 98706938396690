<template>
    <v-container>
        <v-row no-gutters align="start" justify="start">
            <v-btn class="ma-2"  dark href="/checklist">Zpět na výpis
                <v-icon dark right>mdi-atom-variant</v-icon>
            </v-btn>
        </v-row>
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="10" md="6" lg="4">
                <v-card
                    outlined
                    elevation="12"
                    ref="form"
                >
                    <form id="CheckListComplete" method="post" action="/checklist/complete" enctype="application/x-www-form-urlencoded">
                        <input name="_token" v-model="csrf" type="hidden" />
                        <input name="id" v-model="checklist_e.id" type="hidden" />
                        <!-- vyjezdova kontrola -->
                        <input type="hidden" name="exit_plombimg" id="exit_plombimg" v-model="Form.exit_plombimg" />
                        <input type="hidden" name="exit_plombOK" v-model="Form.exit_plombOK">
                        <input type="hidden" name="exit_plomb" v-model="Form.exit_plomb">
                        <input type="hidden" name="exit_chassisimg" id="exit_chassisimg" v-model="Form.exit_chassisimg" />
                        <input type="hidden" name="exit_chassisOK" v-model="Form.exit_chassisOK">
                        <input type="hidden" name="exit_chassis" v-model="Form.exit_chassis">
                        <input type="hidden" name="exit_cabimg" id="exit_cabimg" v-model="Form.exit_cabimg" />
                        <input type="hidden" name="exit_cabOK" v-model="Form.exit_cabOK">
                        <input type="hidden" name="exit_cab" v-model="Form.exit_cab">
                        <input type="hidden" name="exit_note" v-model="Form.exit_note">
                        <v-toolbar
                            color="green"
                            dark
                            flat
                        >
                            <v-toolbar-title >ODJEZDOVÁ KONTROLA</v-toolbar-title>
                            <v-spacer />
                        </v-toolbar>
                        <v-card-text>
                            <p class="text-body-2">Dopravce: <span class="font-weight-bold">{{ checklist_e.company }}</span></p>
                            <p class="text-body-2">SPZ tahač: <span class="font-weight-bold">{{ checklist_e.spz_truck }}</span></p>
                            <p class="text-body-2">SPZ cisterny: <span class="font-weight-bold">{{ checklist_e.spz_cistern }}</span></p>
                            <p class="text-body-2">Řidič: <span class="font-weight-bold">{{ checklist_e.driver_name }} {{ checklist_e.driver_last_name }}</span></p>
                            <v-form v-model="Form.valid" ref="form">
                                <v-stepper v-model="stepper_index" vertical>
                                    <!-- CHASSIS -->
                                    <v-stepper-step step="1" :complete="stepper_index > 1">Celková kontrola rámu</v-stepper-step>
                                    <v-stepper-content step="1">
                                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.exit_chassisOK=!Form.exit_chassisOK" true-value="1" false-value="0"></v-switch>
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    placeholder="popis nedostatků"
                                                    label="Nedostatky: Celková kontrola rámu"
                                                    class="ma-2"
                                                    v-show="!Form.exit_chassisOK"
                                                    v-model="Form.exit_chassis"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row align="start" justify="start">
                                            <v-col>
                                                <v-btn color="error" v-show="!Form.exit_chassisOK" @click="DialogPhoto('Celková kontrola rámu', 'exit_chassis')">
                                                    <v-icon left>mdi-camera-outline</v-icon>
                                                    F O T O
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <canvas v-show="!Form.exit_chassisOK" width="120" height="60" id="canvas_exit_chassis"></canvas>
                                            </v-col>
                                        </v-row>
                                        <canvas hidden id="canvas_exit_chassis_big" width="1280" height="720"></canvas>
                                        <v-spacer/>
                                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                                    </v-stepper-content>
                                    <!-- PLOMB-->
                                    <v-stepper-step step="2" :complete="stepper_index > 2">Plomby</v-stepper-step>
                                    <v-stepper-content step="2">
                                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.exit_plombOK=!Form.exit_plombOK" true-value="1" false-value="0"></v-switch>
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    placeholder="popis nedostatků"
                                                    label="Nedostatky: Plomby"
                                                    class="ma-2"
                                                    v-show="!Form.exit_plombOK"
                                                    v-model="Form.exit_plomb"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row align="start" justify="start">
                                            <v-col>
                                                <v-btn color="error" v-show="!Form.exit_plombOK" @click="DialogPhoto('Plomby', 'exit_plomb')">
                                                    <v-icon left>mdi-camera-outline</v-icon>
                                                    F O T O
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <canvas v-show="!Form.exit_plombOK" width="120" height="60" id="canvas_exit_plomb"></canvas>
                                            </v-col>
                                        </v-row>
                                        <canvas hidden id="canvas_exit_plomb_big" width="1280" height="720"></canvas>
                                        <v-spacer/>
                                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                                    </v-stepper-content>
                                    <!-- DRIVER CAB -->
                                    <v-stepper-step step="3" :complete="stepper_index > 3">Kabina řidiče</v-stepper-step>
                                    <v-stepper-content step="3">
                                        <v-switch color="red" label="nedostatky" class="ma-2" @change="Form.exit_cabOK=!Form.exit_cabOK" true-value="1" false-value="0"></v-switch>
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    placeholder="popis nedostatků"
                                                    label="Nedostatky: Kabina řidiče"
                                                    class="ma-2"
                                                    v-show="!Form.exit_cabOK"
                                                    v-model="Form.exit_cab"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row align="start" justify="start">
                                            <v-col>
                                                <v-btn color="error" v-show="!Form.exit_cabOK" @click="DialogPhoto('Kabina řidiče', 'exit_cab')">
                                                    <v-icon left>mdi-camera-outline</v-icon>
                                                    F O T O
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <canvas v-show="!Form.exit_cabOK" width="120" height="60" id="canvas_exit_cab"></canvas>
                                            </v-col>
                                        </v-row>
                                        <canvas hidden id="canvas_exit_cab_big" width="1280" height="720"></canvas>
                                        <v-spacer/>
                                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                                        <v-btn color="primary" @click="stepper_index++">Další</v-btn>
                                    </v-stepper-content>
                                    <!--  NOTES -->
                                    <v-stepper-step step="4" :complete="stepper_index > 4">Poznámka</v-stepper-step>
                                    <v-stepper-content step="4">
                                        <v-row no-gutters>
                                            <v-col>
                                                <v-textarea
                                                    outlined
                                                    dense
                                                    placeholder="Poznámka"
                                                    label="Poznámka"
                                                    class="ma-2"
                                                    v-model="Form.exit_note"
                                                ></v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-btn color="primary" @click="stepper_index--">Zpět</v-btn>
                                    </v-stepper-content>
                                </v-stepper>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn class="ma-2" color="secondary" @click="FormSend('CheckListComplete')" :disabled="stepper_index < 4">Uložit</v-btn>
                        </v-card-actions>
                    </form>
                </v-card>
                <v-dialog
                    v-model="dialog"
                    fullscreen
                >
                    <v-card>
                        <!-- <v-card-title class="headline justify-center">Fotoaparát - {{ dialog_title }}</v-card-title> -->
                        <v-card-text class="text-center">
                            <v-row no-gutters align="center" justify="center" align-content="center">
                                <v-col>
                                    <video ref="video" id="vid-show" width="90%" height="90%" autoplay></video>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="PhotoSave">
                                <v-icon left>mdi-camera-outline</v-icon>
                                Vyfotit a uložit
                            </v-btn>
                            <v-btn color="primary" @click="dialog = false">Zavřít</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "CheckListComplete",
    props: {
      checklist: String
    },
    data () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            stepper_index: 1,
            dialog: false,
            dialog_title: '',
            canvas_name: '',
            checklist_e: Object,
            Form: {
                /* Vyjezdova kontrola */
                exit_plombOK: true,
                exit_plomb: null,
                exit_plombimg: null,
                exit_chassisOK: true,
                exit_chassis: null,
                exit_chassisimg: null,
                exit_cabOK: true,
                exit_cab: null,
                exit_cabimg: null,
                exit_note: null,
                valid: false
            }
        }
    },
    methods: {
        async DialogPhoto(title, name) {
            this.dialog_title = title
            this.canvas_name = name
            this.dialog = true
            document.querySelector('video').srcObject = await navigator.mediaDevices.getUserMedia({ video : {
                    facingMode: 'environment',
                    frameRate: { ideal: 10, max: 15 },
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                } , audio: false })
        },
        PhotoSave() {
            let canvas = document.getElementById('canvas_'+this.canvas_name)
            canvas.width = 120
            canvas.height = 60
            canvas.getContext('2d').drawImage(document.querySelector('video'), 0, 0, 120, 60)
            let canvasBig = document.getElementById('canvas_'+this.canvas_name+'_big')
            canvasBig.width = 1280
            canvasBig.height = 720
            canvasBig.getContext('2d').drawImage(document.querySelector('video'), 0, 0, 1280, 720)
            const reader = new FileReader();
            let self = this
            canvasBig.toBlob(function (blob){
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    eval("self.Form."+self.canvas_name+"img = reader.result");
                }
            }, 'image/jpeg', 0.9)
            this.dialog = false
        },
        FormSend(FormName) {
            let form2send = document.getElementById(FormName)
            form2send.submit()
        }
    },
    mounted() {
        this.checklist_e = JSON.parse(this.checklist)
    }
}
</script>

<style scoped>

</style>
