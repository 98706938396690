<template>
        <v-row
            align="center"
            justify="center"
        >
            <v-col
                cols="12"
                sm="8"
                md="4"
            >
                <v-alert
                    type="error"
                    v-for="error in errorJSON" :key="error"
                    border="top"
                    dismissible
                    dense
                >
                    {{ error }}
                </v-alert>
                <v-card class="elevation-12">
                    <v-toolbar
                        color="primary"
                        dark
                        flat
                    >
                        <v-toolbar-title>Login form</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <form  method="post" ref="formLogin" id="formLogin" action="/login" enctype="application/x-www-form-urlencoded">
                        <input name="email" value="" hidden v-model="form.email">
                        <input name="password" value="" hidden v-model="form.password">
                        <input name="_token" v-model="csrf" hidden>
                    <v-card-text>
                        <v-form v-model="valid" ref="formLogin">
                            <v-text-field
                                label="Login"
                                name="login"
                                prepend-icon="mdi-account"
                                type="text"
                                v-model="form.email"
                                :rules="emailRules"
                                validate-on-blur
                                @keyup.enter="FormSubmit"
                            />
                            <v-text-field
                                id="password"
                                label="Password"
                                name="password"
                                prepend-icon="mdi-lock"
                                type="password"
                                v-model="form.password"
                                :rules="passRules"
                                validate-on-blur
                                @keyup.enter="FormSubmit"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" type="submit" :disabled="!valid">Login</v-btn>
                    </v-card-actions>
                     </form>
                </v-card>
            </v-col>
        </v-row>
</template>

<script>
    export default {
        name: "LoginForm",
        props: {
            error_message: {
                type: String,
                default: function () {
                    return "[]"
                }
            }
        },
        data: () => ({
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            valid: false,
            form: {
                email: '',
                password: '',
            },
            errorJSON: [],
            emailRules: [
                v => !!v || 'E-mail je poviny',
                v => /.+@.+/.test(v) || 'E-mail musí mít správný tvar'
            ],
            passRules: [
                v => !!v || 'Heslo je povinné'
            ]
        }),
        methods: {
            FormSubmit() {
                document.getElementById('formLogin').submit();
            }
        },
        mounted() {
            this.errorJSON = JSON.parse(this.error_message)
        }
    }
</script>

<style scoped>

</style>
