<template>
    <v-container>
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
    >
        <v-list dense>
            <v-list-item-group>
            <v-list-item link href="/login" v-if="!username">
                <v-list-item-action>
                    <v-icon>mdi-account-key</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Přihlásit se</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link href="/dashboard" v-if="username && role=='admin'">
                <v-list-item-action>
                    <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link href="/users" v-if="username && role=='admin'">
                <v-list-item-action>
                    <v-icon>mdi-account-group</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Uživatelé</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link href="/checklist" v-if="username">
                <v-list-item-action>
                    <v-icon>mdi-atom-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Checklist</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list-item-group>
            <v-list-item link onclick="document.getElementById('FormLogout').submit()" href="#" v-if="username" style="position: absolute; bottom:0; width: 100%">
                <v-list-item-action>
                    <v-icon>mdi-power</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Odhlásit se</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <form method="post" action="/logout" id="FormLogout">
                <input type="hidden" name="_token" v-model="csrf">
            </form>
        </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        clipped-left
    >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>
    </v-container>
</template>

<script>
    export default {
        name: "Menu.vue",
        props: {
            username: String,
            role: String,
            title: {
                type: String,
                default: function () {
                    return { message: 'Application' }
                }
            }
        },
        data: () => ({
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            drawer: false,
        })
    }
</script>

<style scoped>

</style>
