<template>
    <v-container>
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="10" md="6" lg="4">
                <v-card
                    outlined
                    elevation="12"
                    ref="form"
                >
                    <v-toolbar
                        color="blue"
                        dark
                        flat
                    >
                        <v-toolbar-title >PŘIDAT UŽIVATELE</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-card-text>
                        <form id="UserAddForm" method="post" action="/user/add" enctype="application/x-www-form-urlencoded">
                            <input name="_token" v-model="csrf" type="hidden" />
                            <input name="name" v-model="Form.name" type="hidden" />
                            <input name="email" v-model="Form.email" type="hidden" />
                            <input name="password" v-model="Form.password" type="hidden" />
                            <input name="role" v-model="Form.role" type="hidden" />
                        </form>
                        <v-form v-model="form_valid">
                            <v-text-field
                                outlined
                                dense
                                placeholder="Jméno"
                                label="Jméno"
                                class="ma-2"
                                v-model="Form.name"
                                :rules="[v => !v || v.length>3 || 'Jméno musí mít alespoň 4 znaků']"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                dense
                                placeholder="e-mail"
                                label="e-mail"
                                class="ma-2"
                                v-model="Form.email"
                                :rules="[v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail musí mít správný formát']"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                dense
                                placeholder="heslo"
                                label="heslo"
                                class="ma-2"
                                v-model="Form.password"
                                type="password"
                                :rules="[Form.password === Form.password_confirm || 'Hesla musí souhlasit', v => !v || v.length>3 || 'Heslo musí mít alespoň 4 znaky']"
                            ></v-text-field>
                            <v-text-field
                                outlined
                                dense
                                placeholder="heslo - potvrzení"
                                label="heslo - potvrzení"
                                class="ma-2"
                                v-model="Form.password_confirm"
                                type="password"
                                :rules="[Form.password === Form.password_confirm || 'Hesla musí souhlasit']"
                            ></v-text-field>
                            <v-checkbox
                                label="Administrátor"
                                v-model="Form.role"
                                true-value="admin"
                                false-value="user"
                            ></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn class="ma-2" color="secondary" @click="FormSend('UserAddForm')" :disabled="!form_valid">Přidat</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "UserAdd.vue",
    data () {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            form_valid: false,
            Form: {
                name: '',
                email: '',
                password: '',
                password_confirm: '',
                role: 'user'
            }
        }
    },
    methods: {
        FormSend(FormName) {
            let form2send = document.getElementById(FormName)
            form2send.submit()
        }
    },
}
</script>

<style scoped>

</style>
