/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./axios');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('login-form', require('./components/LoginForm.vue').default);
Vue.component('menu-component', require('./components/Menu.vue').default);
Vue.component('checklist-component', require('./components/CheckList.vue').default);
Vue.component('checklist-add-component', require('./components/CheckListAdd.vue').default);
Vue.component('checklist-complete-component', require('./components/ChecklistComplete.vue').default);
Vue.component('checklist-show-component', require('./components/CheckListShow.vue').default);

Vue.component('users-component', require('./components/Users.vue').default);
Vue.component('user-edit-component', require('./components/UserEdit.vue').default);
Vue.component('user-add-component', require('./components/UserAdd.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Vuetify, {
    VLayout,
    VRow,
    VCol,
    VSpacer,
    VDivider,
    VBtn,
    VAlert,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VDialog,
    VContainer,
    VToolbar,
    VToolbarTitle,
    VApp,
    VMain,
    VIcon,
    VFooter,
    VForm,
    VTextField,
    VTextarea,
    VNavigationDrawer,
    VList,
    VListItem,
    VListItemSubtitle,
    VListItemAction,
    VListItemGroup,
    VListItemContent,
    VListItemTitle,
    VSimpleTable,
    VAppBar,
    VAppBarNavIcon,
    VStepper,
    VStepperStep,
    VStepperContent,
    VSwitch,
    VTooltip,
    VImg,
    VSnackbar,
    VDatePicker,
    VMenu,
    VCheckbox,
    VAutocomplete,
    VSubheader
} from 'vuetify/lib'

Vue.use(Vuetify, {
    components: {
        VLayout,
        VRow,
        VCol,
        VSpacer,
        VDivider,
        VAlert,
        VBtn,
        VCard,
        VCardText,
        VCardTitle,
        VCardActions,
        VDialog,
        VContainer,
        VToolbar,
        VToolbarTitle,
        VApp,
        VMain,
        VFooter,
        VForm,
        VTextField,
        VTextarea,
        VIcon,
        VNavigationDrawer,
        VList,
        VListItem,
        VListItemSubtitle,
        VListItemContent,
        VListItemTitle,
        VListItemAction,
        VListItemGroup,
        VSimpleTable,
        VAppBar,
        VAppBarNavIcon,
        VStepper,
        VStepperStep,
        VStepperContent,
        VSwitch,
        VTooltip,
        VImg,
        VSnackbar,
        VDatePicker,
        VMenu,
        VCheckbox,
        VAutocomplete,
        VSubheader
    }
});
new Vue({
    el: '#app',
    vuetify: new Vuetify()
});
